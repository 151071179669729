// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-clienti-jsx": () => import("./../../../src/pages/area-clienti.jsx" /* webpackChunkName: "component---src-pages-area-clienti-jsx" */),
  "component---src-pages-articolo-jsx": () => import("./../../../src/pages/articolo.jsx" /* webpackChunkName: "component---src-pages-articolo-jsx" */),
  "component---src-pages-azienda-js": () => import("./../../../src/pages/azienda.js" /* webpackChunkName: "component---src-pages-azienda-js" */),
  "component---src-pages-carrello-jsx": () => import("./../../../src/pages/carrello.jsx" /* webpackChunkName: "component---src-pages-carrello-jsx" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-ordine-jsx": () => import("./../../../src/pages/ordine.jsx" /* webpackChunkName: "component---src-pages-ordine-jsx" */),
  "component---src-pages-password-reset-jsx": () => import("./../../../src/pages/password-reset.jsx" /* webpackChunkName: "component---src-pages-password-reset-jsx" */),
  "component---src-pages-punti-vendita-jsx": () => import("./../../../src/pages/punti-vendita.jsx" /* webpackChunkName: "component---src-pages-punti-vendita-jsx" */),
  "component---src-pages-recupero-password-jsx": () => import("./../../../src/pages/recupero-password.jsx" /* webpackChunkName: "component---src-pages-recupero-password-jsx" */),
  "component---src-pages-registrazione-agente-jsx": () => import("./../../../src/pages/registrazione-agente.jsx" /* webpackChunkName: "component---src-pages-registrazione-agente-jsx" */),
  "component---src-pages-registrazione-avvenuta-jsx": () => import("./../../../src/pages/registrazione-avvenuta.jsx" /* webpackChunkName: "component---src-pages-registrazione-avvenuta-jsx" */),
  "component---src-pages-registrazione-jsx": () => import("./../../../src/pages/registrazione.jsx" /* webpackChunkName: "component---src-pages-registrazione-jsx" */),
  "component---src-pages-soluzioni-js": () => import("./../../../src/pages/soluzioni.js" /* webpackChunkName: "component---src-pages-soluzioni-js" */),
  "component---src-pages-storico-ordini-jsx": () => import("./../../../src/pages/storico-ordini.jsx" /* webpackChunkName: "component---src-pages-storico-ordini-jsx" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/news/category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news/single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-products-category-js": () => import("./../../../src/templates/products/category.js" /* webpackChunkName: "component---src-templates-products-category-js" */),
  "component---src-templates-products-single-js": () => import("./../../../src/templates/products/single.js" /* webpackChunkName: "component---src-templates-products-single-js" */)
}

